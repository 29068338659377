<template>
  <div class="row">
<!--    <div class="col-lg-12">-->
<!--      <div class="row">-->
<!--        <div class="col-lg-12">-->
<!--          <div class="row">-->
<!--            <div class="col-lg-4 col-md-12 d-flex justify-content-start">-->
<!--              <div class="h-100 w-100 d-flex align-items-end">-->
<!--                <div class="input-group input-group-sm">-->
<!--                  <span class="input-group-addon">-->
<!--                    <i class="fa fa-search"></i>-->
<!--                  </span>-->
<!--                  <input-->
<!--                    id="search"-->
<!--                    name="search"-->
<!--                    :placeholder="$t('aps.search')"-->
<!--                    class="form-control filters__input"-->
<!--                    v-model="filters.query"-->
<!--                  />-->
<!--                  <span class="clear-input text-muted">-->
<!--                    <i class="fa fa-remove" @click="searchClear"></i>-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-lg-4 col-md-12 d-flex justify-content-center">-->
<!--              <div class="h-100 w-100 d-flex align-items-end">-->
<!--                &lt;!&ndash;              <div>&ndash;&gt;-->
<!--                &lt;!&ndash;              <small>{{ $t('general.filtersSort') }}</small>&ndash;&gt;-->
<!--                &lt;!&ndash;              </div>&ndash;&gt;-->
<!--                <div class="input-group input-group-sm">-->
<!--                  <span class="input-group-addon filters__field-icon">-->
<!--                    <i-->
<!--                      class="fa fa-sort-amount-desc text-primary icon-sort"-->
<!--                      v-if="sorting.sortMode === 'desc'"-->
<!--                      @click="toggleSortMode()"-->
<!--                    ></i>-->
<!--                    <i-->
<!--                      class="fa fa-sort-amount-asc text-primary icon-sort"-->
<!--                      v-if="sorting.sortMode === 'asc'"-->
<!--                      @click="toggleSortMode()"-->
<!--                    ></i>-->
<!--                  </span>-->
<!--                  <select class="form-control form-control-sm filters__field" v-model="sorting.sortByField">-->
<!--                    <option :selected="true" value="ns">{{ $t('DBState.sortingSortByNs') }}</option>-->
<!--                    <option :selected="true" value="count">{{ $t('DBState.sortingSortByCount') }}</option>-->
<!--                    <option :selected="true" value="size">{{ $t('DBState.sortingSortBySize') }}</option>-->
<!--                    <option :selected="true" value="storageSize">{{ $t('DBState.sortingSortByStorageSize') }}</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-lg-4 col-md-12 d-flex justify-content-end">-->
<!--              <div class="h-100 d-flex align-items-end">-->
<!--                <div>-->
<!--                  <div>-->
<!--                    <small>{{ $t('DBState.units') }}</small>-->
<!--                  </div>-->
<!--                  <div class="btn-group btn-group-sm float-left" data-toggle="button">-->
<!--                    <label-->
<!--                      role="button"-->
<!--                      class="btn btn btn-outline-success mb-0"-->
<!--                      :class="{ active: showingUnits === 'Bytes' }"-->
<!--                    >-->
<!--                      <input type="radio" name="typeFilter" @click="setShowingUnits('Bytes')" />-->
<!--                      {{ $t('DBState.Bytes') }}-->
<!--                    </label>-->
<!--                    <label-->
<!--                      role="button"-->
<!--                      class="btn btn btn-outline-success mb-0"-->
<!--                      :class="{ active: showingUnits === 'Kbytes' }"-->
<!--                    >-->
<!--                      <input type="radio" name="typeFilter" @click="setShowingUnits('Kbytes')" />-->
<!--                      {{ $t('DBState.Kbytes') }}-->
<!--                    </label>-->
<!--                    <label-->
<!--                      role="button"-->
<!--                      class="btn btn btn-outline-success mb-0"-->
<!--                      :class="{ active: showingUnits === 'Mbytes' }"-->
<!--                    >-->
<!--                      <input type="radio" name="typeFilter" @click="setShowingUnits('Mbytes')" />-->
<!--                      {{ $t('DBState.Mbytes') }}-->
<!--                    </label>-->
<!--                    <label-->
<!--                      role="button"-->
<!--                      class="btn btn btn-outline-success mb-0"-->
<!--                      :class="{ active: showingUnits === 'Gbytes' }"-->
<!--                    >-->
<!--                      <input type="radio" name="typeFilter" @click="setShowingUnits('Gbytes')" />-->
<!--                      {{ $t('DBState.Gbytes') }}-->
<!--                    </label>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="row mt-h ">-->
<!--                                <div v-if="isFiltersActive" class="col-lg-4 mb-1">-->
<!--              <button class="btn-outline-secondary btn btn-sm new-modern-style-btn-in-filters" @click="resetAllFilters">-->
<!--                <i class="fa fa-close"></i>-->
<!--                {{ $t('general.resetAllFilters') }}-->
<!--              </button>-->
<!--            </div>-->
<!--            &lt;!&ndash;            <div class="col-12">&ndash;&gt;-->
<!--            &lt;!&ndash;          <div class=" mb-1">&ndash;&gt;-->
<!--            &lt;!&ndash;            <div>&ndash;&gt;-->
<!--            &lt;!&ndash;              <small>{{ $t('SNMPStat.availability') }}</small>&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--            &lt;!&ndash;            <div class="btn-group btn-group-sm float-left mr-1" data-toggle="button">&ndash;&gt;-->
<!--            &lt;!&ndash;              <label class="btn btn btn-outline-success"&ndash;&gt;-->
<!--            &lt;!&ndash;                     :class="{ active: filters.availability === 'All' }">&ndash;&gt;-->
<!--            &lt;!&ndash;                <input&ndash;&gt;-->
<!--            &lt;!&ndash;                  type="radio"&ndash;&gt;-->
<!--            &lt;!&ndash;                  name="typeFilter"&ndash;&gt;-->
<!--            &lt;!&ndash;                  @click="setAvailabilityFilter('All')"&ndash;&gt;-->
<!--            &lt;!&ndash;                />&ndash;&gt;-->
<!--            &lt;!&ndash;                {{ $t('SNMPStat.filtersAvailablyAll') }}&ndash;&gt;-->
<!--            &lt;!&ndash;              </label>&ndash;&gt;-->
<!--            &lt;!&ndash;              <label class="btn btn btn-outline-success"&ndash;&gt;-->
<!--            &lt;!&ndash;                     :class="{ active: filters.availability === 'Availably' }">&ndash;&gt;-->
<!--            &lt;!&ndash;                <input&ndash;&gt;-->
<!--            &lt;!&ndash;                  type="radio"&ndash;&gt;-->
<!--            &lt;!&ndash;                  name="typeFilter"&ndash;&gt;-->
<!--            &lt;!&ndash;                  @click="setAvailabilityFilter('Availably')"&ndash;&gt;-->
<!--            &lt;!&ndash;                />&ndash;&gt;-->
<!--            &lt;!&ndash;                {{ $t('SNMPStat.filtersAvailablyAvailably') }}&ndash;&gt;-->
<!--            &lt;!&ndash;              </label>&ndash;&gt;-->
<!--            &lt;!&ndash;              <label class="btn btn btn-outline-danger"&ndash;&gt;-->
<!--            &lt;!&ndash;                     :class="{ active: filters.availability === 'NotAvailably' }">&ndash;&gt;-->
<!--            &lt;!&ndash;                <input&ndash;&gt;-->
<!--            &lt;!&ndash;                  type="radio"&ndash;&gt;-->
<!--            &lt;!&ndash;                  name="typeFilter"&ndash;&gt;-->
<!--            &lt;!&ndash;                  @click="setAvailabilityFilter('NotAvailably')"&ndash;&gt;-->
<!--            &lt;!&ndash;                />&ndash;&gt;-->
<!--            &lt;!&ndash;                {{ $t('SNMPStat.filtersAvailablyNotAvailably') }}&ndash;&gt;-->
<!--            &lt;!&ndash;              </label>&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--            &lt;!&ndash;          </div>&ndash;&gt;-->
<!--            &lt;!&ndash;          </div>&ndash;&gt;-->
<!--          </div>-->
<!--          <div class="row mb-1">-->
<!--            <transition name="fadeINDBStateTable">-->
<!--              &lt;!&ndash;            <div class="col-12" v-if="!isFiltersAndSortingsSetToDefault">&ndash;&gt;-->
<!--              &lt;!&ndash;              <button class=" btn-outline-secondary btn btn-sm" @click="clearAllFiltersAndSortings">&ndash;&gt;-->
<!--              &lt;!&ndash;                <i class="fa fa-close"></i>&ndash;&gt;-->
<!--              &lt;!&ndash;                {{ $t('general.resetAllFilters') }}&ndash;&gt;-->
<!--              &lt;!&ndash;              </button>&ndash;&gt;-->
<!--              &lt;!&ndash;            </div>&ndash;&gt;-->
<!--            </transition>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->


    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-3 col-md-12 d-flex justify-content-start mb-h pr-0">
              <div class="h-100 w-100 d-flex align-items-end">
                <div class="input-group input-group-sm">
                  <span class="input-group-addon">
                    <i class="fa fa-search"></i>
                  </span>
                  <input
                    id="search"
                    name="search"
                    :placeholder="$t('aps.search')"
                    class="form-control filters__input"
                    v-model="filters.query"
                  />
                  <span class="clear-input text-muted">
                    <i class="fa fa-remove" @click="searchClear"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-12 d-flex justify-content-center mb-h pr-0">
              <div class="h-100 w-100 d-flex align-items-end">
                <!--              <div>-->
                <!--              <small>{{ $t('general.filtersSort') }}</small>-->
                <!--              </div>-->
                <div class="input-group input-group-sm">
                  <span class="input-group-addon filters__field-icon">
                    <i
                      class="fa fa-sort-amount-desc text-primary icon-sort"
                      v-if="sorting.sortMode === 'desc'"
                      @click="toggleSortMode()"
                    ></i>
                    <i
                      class="fa fa-sort-amount-asc text-primary icon-sort"
                      v-if="sorting.sortMode === 'asc'"
                      @click="toggleSortMode()"
                    ></i>
                  </span>
                  <select class="form-control form-control-sm filters__field" v-model="sorting.sortByField">
                    <option :selected="true" value="ns">{{ $t('DBState.sortingSortByNs') }}</option>
                    <option :selected="true" value="count">{{ $t('DBState.sortingSortByCount') }}</option>
                    <option :selected="true" value="size">{{ $t('DBState.sortingSortBySize') }}</option>
                    <option :selected="true" value="storageSize">{{ $t('DBState.sortingSortByStorageSize') }}</option>
                  </select>
                </div>
              </div>
            </div>

          </div>

                <div class="row mt-h">
                              <div class="col-lg-4 col-md-12 d-flex justify-content-start">
              <div class="h-100 d-flex align-items-end">
                <div>
                  <div>
                    <small>{{ $t('DBState.units') }}</small>
                  </div>
                  <div class="btn-group btn-group-sm float-left" data-toggle="button">
                    <label
                      role="button"
                      class="btn btn btn-outline-info mb-0"
                      :class="{ active: showingUnits === 'Bytes' }"
                    >
                      <input type="radio" name="typeFilter" @click="setShowingUnits('Bytes')" />
                      {{ $t('DBState.Bytes') }}
                    </label>
                    <label
                      role="button"
                      class="btn btn btn-outline-info mb-0"
                      :class="{ active: showingUnits === 'Kbytes' }"
                    >
                      <input type="radio" name="typeFilter" @click="setShowingUnits('Kbytes')" />
                      {{ $t('DBState.Kbytes') }}
                    </label>
                    <label
                      role="button"
                      class="btn btn btn-outline-info mb-0"
                      :class="{ active: showingUnits === 'Mbytes' }"
                    >
                      <input type="radio" name="typeFilter" @click="setShowingUnits('Mbytes')" />
                      {{ $t('DBState.Mbytes') }}
                    </label>
                    <label
                      role="button"
                      class="btn btn btn-outline-info mb-0"
                      :class="{ active: showingUnits === 'Gbytes' }"
                    >
                      <input type="radio" name="typeFilter" @click="setShowingUnits('Gbytes')" />
                      {{ $t('DBState.Gbytes') }}
                    </label>
                  </div>
                </div>
              </div>
            </div>

      </div>

          <div class="row mt-h ">
            <div v-if="isFiltersActive" class="col-lg-4 mb-1">
              <WButton secondary outline sm
                @click="resetAllFilters"
              >
                <i class="fa fa-close"></i>
                {{ $t('general.resetAllFilters') }}
              </WButton>
            </div>
            <!--            <div class="col-12">-->
            <!--          <div class=" mb-1">-->
            <!--            <div>-->
            <!--              <small>{{ $t('SNMPStat.availability') }}</small>-->
            <!--            </div>-->
            <!--            <div class="btn-group btn-group-sm float-left mr-1" data-toggle="button">-->
            <!--              <label class="btn btn btn-outline-success"-->
            <!--                     :class="{ active: filters.availability === 'All' }">-->
            <!--                <input-->
            <!--                  type="radio"-->
            <!--                  name="typeFilter"-->
            <!--                  @click="setAvailabilityFilter('All')"-->
            <!--                />-->
            <!--                {{ $t('SNMPStat.filtersAvailablyAll') }}-->
            <!--              </label>-->
            <!--              <label class="btn btn btn-outline-success"-->
            <!--                     :class="{ active: filters.availability === 'Availably' }">-->
            <!--                <input-->
            <!--                  type="radio"-->
            <!--                  name="typeFilter"-->
            <!--                  @click="setAvailabilityFilter('Availably')"-->
            <!--                />-->
            <!--                {{ $t('SNMPStat.filtersAvailablyAvailably') }}-->
            <!--              </label>-->
            <!--              <label class="btn btn btn-outline-danger"-->
            <!--                     :class="{ active: filters.availability === 'NotAvailably' }">-->
            <!--                <input-->
            <!--                  type="radio"-->
            <!--                  name="typeFilter"-->
            <!--                  @click="setAvailabilityFilter('NotAvailably')"-->
            <!--                />-->
            <!--                {{ $t('SNMPStat.filtersAvailablyNotAvailably') }}-->
            <!--              </label>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--          </div>-->
          </div>
          <div class="row mb-1">
            <transition name="fadeINDBStateTable">
              <!--            <div class="col-12" v-if="!isFiltersAndSortingsSetToDefault">-->
              <!--              <button class=" btn-outline-secondary btn btn-sm" @click="clearAllFiltersAndSortings">-->
              <!--                <i class="fa fa-close"></i>-->
              <!--                {{ $t('general.resetAllFilters') }}-->
              <!--              </button>-->
              <!--            </div>-->
            </transition>
          </div>
        </div>
      </div>
    </div>


    <div class="col-lg-12 mt-1">
      <div class="">
        <div class="animated fadeIn">
          <div class="animated fadeIn sticky-top">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <div v-if="isShowOverTableSpinner"><Loader-spinner></Loader-spinner></div>
                  <div class="card-header d-flex justify-content-between">
                    <div class="d-flex align-items-end">
                      <div>
                        <i class="glyphicons glyphicons-database"></i>
                      </div>
                      <div>
                        <span>{{ $t('DBState.tableTitle') }}</span>
                        <span class="text-muted ml-1">
                          {{ dbStateItemsFilteredTotal }} {{ $t('aps.tableItemsOf') }} {{ dbStateItemsTotal }}
                        </span>
                      </div>
                    </div>
                    <div class="">
<!--                      <download-extract-v2-->
<!--                        :pdfTableColumnsWidths="pdfTableColumnsWidths"-->
<!--                        new-xls-auto-columns-width-->
<!--                        v-if="filteredAndSortedDBStateList"-->
<!--                        class="ml-h"-->
<!--                        :data="formatToPDF(filteredAndSortedDBStateList)"-->
<!--                        :data-for-excel="formatToExcel(filteredAndSortedDBStateList)"-->
<!--                        :filename="`DBState-${moment(new Date()).format('lll')}`"-->
<!--                        :excel-file-name="`DbState-${moment(new Date()).format('lll')}`"-->
<!--                        excel-file-type="xlsx"-->
<!--                        :downloads-formats-for-show="{-->
<!--                          oldCsv: true,-->
<!--                          newCsv: false,-->
<!--                          oldXls: false,-->
<!--                          newXls: true,-->
<!--                          pdf: true-->
<!--                        }"-->
<!--                      />-->
                      <download-extract-v2
                        :pdfTableColumnsWidths="pdfTableColumnsWidths"
                        newXlsAutoColumnsWidth
                        v-if="filteredAndSortedDBStateList"
                        class="ml-h"
                        :data="formatToPDF(filteredAndSortedDBStateList)"
                        :data-for-excel="formatToExcel(filteredAndSortedDBStateList)"
                        :filename="getFileNameForDownloadFiles($t('downloadFilesNames.monitoringDBState'))"
                        :excel-file-name="getFileNameForDownloadFiles($t('downloadFilesNames.monitoringDBState'))"
                        excel-file-type="xlsx"
                        :downloads-formats-for-show="{
                          oldCsv: true,
                          newCsv: false,
                          oldXls: false,
                          newXls: true,
                          pdf: true
                        }"
                      />
                    </div>
                  </div>
                  <div class="card-block card-block__table table-responsive table-with-db-states-wrapper">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th class="text-left align-text-top">{{ $t('DBState.tableColumnNs') }}</th>
                          <th class="text-right align-text-top">{{ $t('DBState.tableColumnCount') }}</th>
                          <th class="text-right align-text-top">
                            <div>
                              <div>{{ $t('DBState.tableColumnSize') }}</div>
                              <transition name="fadeINDBStateTable">
                                <div v-if="isShowUnitsInTable">({{ getCurrentUnitCaptionForTable() }})</div>
                              </transition>
                            </div>
                          </th>
                          <th class="text-right align-text-top">
                            <div>
                              <div>{{ $t('DBState.tableColumnStorageSize') }}</div>
                              <transition name="fadeINDBStateTable">
                                <div v-if="isShowUnitsInTable">({{ getCurrentUnitCaptionForTable() }})</div>
                              </transition>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(DBStateItem, index) in filteredAndSortedDBStateList"
                          :key="DBStateItem.ns"
                          v-if="DBStateItem.ns"
                        >
                          <td class="text-left responsive-row max-width-column-ns-dbstate-table">
                            <span v-tooltip="{ content: DBStateItem.ns }" v-if="DBStateItem.ns">
                              {{ DBStateItem.ns }}
                            </span>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td class="text-right responsive-row max-width-column-dbstate-table">
                            <template
                              v-tooltip="{ content: DBStateItem.count }"
                              v-if="DBStateItem.hasOwnProperty('count')"
                            >
                              <span v-if="DBStateItem.count !== ''">{{ DBStateItem.count }}</span>
                              <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                            </template>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td class="text-right responsive-row max-width-column-dbstate-table">
                            <template
                              v-tooltip="{ content: DBStateItem.size }"
                              v-if="DBStateItem.hasOwnProperty('size')"
                            >
                              <transition name="fadeINDBStateTable">
                                <span v-if="DBStateItem.size !== '' && isShowUnitsInTable">{{ DBStateItem.size }}</span>
                              </transition>
                              <span v-if="DBStateItem.size === ''" class="text-muted">{{ $t('misc.no_data') }}</span>
                            </template>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                          <td class="text-right responsive-row max-width-column-dbstate-table">
                            <template
                              v-tooltip="{ content: DBStateItem.storageSize }"
                              v-if="DBStateItem.hasOwnProperty('storageSize')"
                            >
                              <transition name="fadeINDBStateTable">
                                <span v-if="DBStateItem.storageSize !== '' && isShowUnitsInTable">
                                  {{ DBStateItem.storageSize }}
                                </span>
                              </transition>
                              <span v-if="DBStateItem.storageSize === ''" class="text-muted">
                                {{ $t('misc.no_data') }}
                              </span>
                            </template>
                            <span v-else class="text-muted">{{ $t('misc.no_data') }}</span>
                          </td>
                        </tr>
                        <tr class="table-with-db-states-total-row" v-if="filteredAndSortedDBStateList.length > 1">
                          <td class="text-left responsive-row max-width-column-ns-dbstate-table">
                            <b>{{ $t('portalLocationsStat.total') }}</b>
                          </td>
                          <td class="text-right responsive-row max-width-column-dbstate-table">
                            <b>
                              {{ dbStateFilteredObjectsTotal }}
                            </b>
                          </td>
                          <td class="text-right responsive-row max-width-column-dbstate-table">
                            <b>
                              {{ dbStateFilteredSizeTotal }}
                            </b>
                          </td>
                          <td class="text-right responsive-row max-width-column-dbstate-table">
                            <b>
                              {{ dbStateFilteredStorageSizeTotal }}
                            </b>
                          </td>
                        </tr>
                        <tr v-if="!filteredAndSortedDBStateList.length">
                          <td colspan="20" class="no-data-row">
                            <span class="text-muted pt-1">{{ $t('general.noDataToDisplay') }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DBStateService from '../../services/DBStateService';
import helpers from '../../helpers';

export default {
  name: 'DBState',
  data() {
    return {
      pdfTableColumnsWidths: ['25%', '25%', '25%', '25%'],
      dbStateList: [],
      filters: {
        query: ''
      },
      sorting: {
        sortByField: 'ns',
        sortMode: 'asc'
      },
      showingUnits: 'Bytes',
      isShowUnitsInTable: true
    };
  },
  methods: {
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    formatToPDF(DBStateList) {
      if (!DBStateList) {
        return [];
      }
      let csvDBStateList = JSON.parse(JSON.stringify(DBStateList));
      csvDBStateList = csvDBStateList.map((dbStateItem) => {
        if (Object.prototype.hasOwnProperty.call(dbStateItem, 'ns')) {
          dbStateItem.ns = dbStateItem.ns;
        } else {
          dbStateItem.ns = '';
        }
        if (Object.prototype.hasOwnProperty.call(dbStateItem, 'count')) {
          dbStateItem.count = dbStateItem.count;
        } else {
          dbStateItem.count = '';
        }
        if (Object.prototype.hasOwnProperty.call(dbStateItem, 'size')) {
          dbStateItem.size = dbStateItem.size;
        } else {
          dbStateItem.size = '';
        }
        if (Object.prototype.hasOwnProperty.call(dbStateItem, 'storageSize')) {
          dbStateItem.storageSize = dbStateItem.storageSize || '';
        } else {
          dbStateItem.storageSize = '';
        }

        // дальше выстраиваем в определнном порядке колонки
        const DBStateListKeys = ['ns', 'count', 'size', 'storageSize'];
        const DBStateListWithSortedKeys = {};
        const DBStateTranslateKeys = {
          ns: this.$t('DBState.tableColumnNs'),
          count: this.$t('DBState.tableColumnCount'),
          size: `${this.$t('DBState.tableColumnSize')} (${this.getCurrentUnitCaptionForTable()})`,
          storageSize: `${this.$t('DBState.tableColumnStorageSize')} (${this.getCurrentUnitCaptionForTable()})`
        };
        for (const key of DBStateListKeys) {
          DBStateListWithSortedKeys[DBStateTranslateKeys[key]] = dbStateItem[key];
        }
        return DBStateListWithSortedKeys;
      });
      // console.log(csvDBStateList)
      return csvDBStateList;
    },
    resetAllFilters() {
      this.filters = {
        query: ''
      };
      this.sorting = {
        sortByField: 'ns',
        sortMode: 'asc'
      };
      this.showingUnits = 'Bytes'
    },
    formatToExcel(DBStateList) {
      const result = {};
      if (DBStateList.length === 0) {
        return result;
      }
      result[this.$t('DBState.tableTitle')] = [];
      const DBStateListToExcel = result[this.$t('DBState.tableTitle')];
      DBStateListToExcel.push([
        this.$t('DBState.tableColumnNs'),
        this.$t('DBState.tableColumnCount'),
        `${this.$t('DBState.tableColumnSize')} (${this.getCurrentUnitCaptionForTable()})`,
        `${this.$t('DBState.tableColumnStorageSize')} (${this.getCurrentUnitCaptionForTable()})`
      ]);

      DBStateList.forEach((dbStateItem) => {
        let ns = '';
        let count = '';
        let size = '';
        let storageSize = '';

        if (Object.prototype.hasOwnProperty.call(dbStateItem, 'ns')) {
          ns = dbStateItem.ns;
        }
        if (Object.prototype.hasOwnProperty.call(dbStateItem, 'count')) {
          count = dbStateItem.count;
        }
        if (Object.prototype.hasOwnProperty.call(dbStateItem, 'size')) {
          size = dbStateItem.size;
        }
        if (Object.prototype.hasOwnProperty.call(dbStateItem, 'storageSize')) {
          storageSize = dbStateItem.storageSize;
        }

        // console.log(cpe);
        DBStateListToExcel.push([ns, count, size, storageSize]);
      });
      // console.log(result)
      return result;
    },
    convertUnitsInDBStateList(dbStatesList) {
      const dbStatesListForUnitsConvert = JSON.parse(JSON.stringify(dbStatesList));
      try {
        for (const item of dbStatesListForUnitsConvert) {
          item.size = this.converBytesToCutrrentUnits(item.size);
          item.storageSize = this.converBytesToCutrrentUnits(item.storageSize);
        }
        return dbStatesListForUnitsConvert;
      } catch (e) {
        return dbStatesListForUnitsConvert;
      }
    },
    converBytesToCutrrentUnits(value) {
      try {
        let result = '';
        if (this.showingUnits === 'Bytes') {
          result = value;
        } else if (this.showingUnits === 'Kbytes') {
          result = Number((value / 1024).toFixed(3));
          if (result === 0) {
            result = Number((value / 1024).toFixed(5));
            if (result === 0) {
              result = Number((value / 1024).toFixed(7));
            }
          }
        } else if (this.showingUnits === 'Mbytes') {
          result = Number((value / 1024 / 1024).toFixed(3));
          if (result === 0) {
            result = Number((value / 1024 / 1024).toFixed(5));
            if (result === 0) {
              result = Number((value / 1024 / 1024).toFixed(7));
            }
          }
        } else if (this.showingUnits === 'Gbytes') {
          result = Number((value / 1024 / 1024 / 1024).toFixed(3));
          if (result === 0) {
            result = Number((value / 1024 / 1024 / 1024).toFixed(5));
            if (result === 0) {
              result = Number((value / 1024 / 1024 / 1024).toFixed(7));
            }
          }
        }
        return result;
      } catch (e) {
        // console.log(e);
        return value;
      }
    },
    getCurrentUnitCaptionForTable() {
      try {
        let result = '';
        // if (this.showingUnits === 'Bytes') {
        //   result = this.$t('DBState.Bytes');
        // } else if (this.showingUnits === 'Kbytes') {
        //   result = this.$t('DBState.Kbytes');
        // } else if (this.showingUnits === 'Mbytes') {
        //   result = this.$t('DBState.Mbytes');
        // } else if (this.showingUnits === 'Gbytes') {
        //   result = this.$t('DBState.Gbytes');
        // }
        if (this.showingUnits === 'Bytes') {
          result = this.$t('DBState.Bytes');
        } else if (this.showingUnits === 'Kbytes') {
          result = this.$t('DBState.KbytesShort');
        } else if (this.showingUnits === 'Mbytes') {
          result = this.$t('DBState.MbytesShort');
        } else if (this.showingUnits === 'Gbytes') {
          result = this.$t('DBState.GbytesShort');
        }
        return result;
      } catch (e) {
        // console.log(e);
        return '';
      }
    },
    setShowingUnits(unitsName) {
      if (this.showingUnits === unitsName) {
        return;
      }
      this.isShowUnitsInTable = false;
      this.showingUnits = unitsName;
      this.$nextTick(() => {
        this.isShowUnitsInTable = true;
      });
    },
    searchClear() {
      this.filters.query = '';
    },
    sortingToDefault() {
      this.sorting.sortByField = 'ns';
      this.sorting.sortMode = 'asc';
    },
    clearAllFiltersAndSortings() {
      this.searchClear();
      this.sortingToDefault();
    },
    getDBStateList() {
      DBStateService.getDBState(this);
    },
    toggleSortMode() {
      if (this.sorting.sortMode === 'desc') {
        this.sorting.sortMode = 'asc';
      } else {
        this.sorting.sortMode = 'desc';
      }
    },
    // filterAndSortStateList(stateList) {
    //   try {
    //     const filteredDBStateList = this.searchInStateList(stateList, this.filters.query);
    //     console.log(filteredDBStateList)
    //     let sortedfilteredStateList = [];
    //     let first;
    //     let second;
    //     if (this.sorting.sortMode === 'desc') {
    //       first = -1;
    //       second = 1;
    //     } else if (this.sorting.sortMode === 'asc') {
    //       first = 1;
    //       second = -1;
    //     }
    //     if (this.sorting.sortByField === 'ns') {
    //       sortedfilteredStateList = filteredDBStateList.sort((curr, prev) => {
    //         if (curr.ns > prev.ns) {
    //           return first;
    //         }
    //         if (curr.ns < prev.ns) {
    //           return second;
    //         }
    //         return 0;
    //       });
    //     }
    //     return sortedfilteredStateList;
    //   } catch (e) {
    //     return stateList;
    //   }
    // },
    filterAndSortStateList(stateList) {
      // this.$store.commit('filteringAndSortingDBStateListBegin');
      try {
        const filteredDBStateList = this.searchInStateList(stateList, this.filters.query);
        // console.log(filteredDBStateList)
        let sortedfilteredStateList = [];
        let first;
        let second;
        if (this.sorting.sortMode === 'desc') {
          first = -1;
          second = 1;
        } else if (this.sorting.sortMode === 'asc') {
          first = 1;
          second = -1;
        }
        if (this.sorting.sortByField === 'ns') {
          sortedfilteredStateList = filteredDBStateList.sort((curr, prev) => {
            if (curr.ns > prev.ns) {
              return first;
            }
            if (curr.ns < prev.ns) {
              return second;
            }
            return 0;
          });
        } else if (this.sorting.sortByField === 'count') {
          sortedfilteredStateList = filteredDBStateList.sort((curr, prev) => {
            if (curr.count > prev.count) {
              return first;
            }
            if (curr.count < prev.count) {
              return second;
            }
            return 0;
          });
        } else if (this.sorting.sortByField === 'size') {
          sortedfilteredStateList = filteredDBStateList.sort((curr, prev) => {
            if (curr.size > prev.size) {
              return first;
            }
            if (curr.size < prev.size) {
              return second;
            }
            return 0;
          });
        } else if (this.sorting.sortByField === 'storageSize') {
          sortedfilteredStateList = filteredDBStateList.sort((curr, prev) => {
            if (curr.storageSize > prev.storageSize) {
              return first;
            }
            if (curr.storageSize < prev.storageSize) {
              return second;
            }
            return 0;
          });
        } else {
          sortedfilteredStateList = filteredDBStateList.sort((curr, prev) => {
            if (curr.ns > prev.ns) {
              return first;
            }
            if (curr.ns < prev.ns) {
              return second;
            }
            return 0;
          });
        }
        return sortedfilteredStateList;
      } catch (e) {
        return stateList;
      } finally {
        // this.$nextTick(() => {
        //   setTimeout(() => {
        //     this.$store.commit('filteringAndSortingDBStateListEnd');
        //   }, 100);
        // });
      }
    },
    searchInStateList(DBStateArray, searchQuery) {
      if (!DBStateArray) {
        return [];
      }
      const DBStateArrayFiltred = JSON.parse(JSON.stringify(DBStateArray));
      return DBStateArrayFiltred.filter((item) => {
        if (
          Object.prototype.hasOwnProperty.call(item, 'ns') &&
          item.ns &&
          item.ns.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return true;
        }
        if (
          Object.prototype.hasOwnProperty.call(item, 'count') &&
          item.count.toString() !== '' &&
          item.count.toString().toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return true;
        }
        if (
          Object.prototype.hasOwnProperty.call(item, 'size') &&
          item.size.toString() !== '' &&
          item.size.toString().toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return true;
        }
        if (
          Object.prototype.hasOwnProperty.call(item, 'storageSize') &&
          item.storageSize.toString() !== '' &&
          item.storageSize.toString().toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return true;
        }

        if (searchQuery) {
          return false;
        }
        return true;
      });
    }
  },
  computed: {
    dbStateItemsTotal() {
      try {
        return this.dbStateList.length;
      } catch (e) {
        return 0;
      }
    },
    dbStateItemsFilteredTotal() {
      try {
        return this.filteredAndSortedDBStateList.length;
      } catch (e) {
        return 0;
      }
    },
    isFiltersActive(){
      return !this.isFiltersAndSortingsSetToDefault || this.showingUnits !== 'Bytes'
    },
    dbStateFilteredObjectsTotal() {
      let result = 0;
      try {
        for (const item of this.filteredAndSortedDBStateList) {
          result += item.count;
        }
        return Number(result.toFixed(3));
      } catch (e) {
        console.log(e);
        return result;
      }
    },
    dbStateFilteredSizeTotal() {
      let result = 0;
      try {
        for (const item of this.filteredAndSortedDBStateList) {
          result += item.size;
        }
        return Number(result.toFixed(3));
      } catch (e) {
        console.log(e);
        return result;
      }
    },
    dbStateFilteredStorageSizeTotal() {
      let result = 0;
      try {
        for (const item of this.filteredAndSortedDBStateList) {
          result += item.storageSize;
        }
        return Number(result.toFixed(3));
      } catch (e) {
        console.log(e);
        return result;
      }
    },
    isFiltersAndSortingsSetToDefault() {
      return this.isFiltersSetToDefault && this.isSortingsSetToDefault;
    },
    isFiltersSetToDefault() {
      return this.filters.query === '';
    },
    isSortingsSetToDefault() {
      return this.sorting.sortByField === 'ns' && this.sorting.sortMode === 'asc';
    },
    filteredAndSortedDBStateList() {
      return this.filterAndSortStateList(this.convertedUnitsDBStateList);
    },
    convertedUnitsDBStateList() {
      return this.convertUnitsInDBStateList(this.dbStateList);
    },
    isShowOverTableSpinner() {
      return this.isDBStatesListLoading || this.isDBStatesListSFilteringAndSorting;
    },
    isDBStatesListLoading() {
      return this.$store.state.loadingDBStateList;
    },
    isDBStatesListSFilteringAndSorting() {
      return this.$store.state.filteringAndSortingDBStateList;
    }
  },
  created() {
    this.getDBStateList();
  }
};
</script>

<style scoped>
/*  .table-with-db-states-wrapper{*/
/*  display: block;*/
/*  overflow-y: auto;*/
/*  max-height: calc(100vh - 335px);*/
/*}*/
/*.table-with-db-states-wrapper table {*/
/*  margin-bottom: 0;*/
/*}*/
/*  .table-with-db-states-wrapper thead {*/
/*  position: sticky;*/
/*  top:  0px;*/

/*  border: 0px;*/
/*    backdrop-filter: blur(20px);*/
/*    !*box-shadow: 0px 0px 0px 1px rgb(0, 0, 0)*!*/
/*}*/
/*  */
/*.table-with-db-states-wrapper .table-with-db-states-total-row {*/
/*  box-shadow: 0px 0px 0px 1px rgb(0, 0, 0);*/

/*     position: sticky;*/
/*  bottom: 0px;*/
/*  backdrop-filter: blur(1px);*/
/*}*/

.table-with-db-states-wrapper {
  display: block;
  overflow-y: auto;
  max-height: calc(100vh - 300px);
}
.table-with-db-states-wrapper table {
  margin-bottom: 0;
}
.table-with-db-states-wrapper thead th {
  position: sticky;
  top: 0;
  background-color: white;
  border: 0px;
}
.table-with-db-states-wrapper .table-with-db-states-total-row td {
  position: sticky;
  bottom: 0px;
  background-color: rgba(236, 236, 236, 1);
}
</style>
<style>
.fadeINDBStateTable-enter-active,
.fadeINDBStateTable-leave-active {
  transition: opacity 0.5s;
}
.fadeINDBStateTable-enter, .fadeINDBStateTable-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
